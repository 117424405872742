import React, { useRef, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import map from "lodash/map"
import readingTime from "reading-time"

import { Media, TextGroup, Link } from "components/common"

import { CtaGetDemo } from "components/cta"

import {
  Layout,
  Seo,
  Nav,
  VerticalSection,
  ScrollProgress,
} from "components/common"
import { useMeta, useRichText, device, richTextBody } from "utils"

import { BlogPosts } from "src/sections/blog"
import classNames from "classnames"

const BlogPost = ({ data: { blogPost, cta_get_demo } }) => {
  const {
    slug,
    title,
    date,
    authors,
    subtitle,
    heroImage,
    cardImage,
    body,
    metaTitle,
    metaDescription,
  } = blogPost

  const ref = useRef()
  const [stats, setStats] = useState({})

  useLayoutEffect(() => {
    const stats = ref.current ? readingTime(ref.current.innerHTML) : {}
    setStats(stats)
  }, [ref])

  const meta = useMeta({
    metaTitle,
    metaDescription,
    metaImage: cardImage,
  })

  const renderBlogBody = useRichText({ text: body })

  return (
    <Layout>
      <Seo {...meta} />
      <StyledBlogPost>
        <Nav />

        <div className="bpHero">
          <VerticalSection
            align="left"
            media={heroImage}
            critical
            customOrder="1"
            mobileCustomOrder="1"
            mobileImageOrder="5"
            custom={() => (
              <div className="heroText">
                <div className="hat">
                  <p className="date small">{date}</p>
                  <span>&bull;</span>
                  <p className="time small">{stats.text}</p>
                </div>
                <TextGroup title={title} subtitle={subtitle} titleAs="h1" />

                {authors && <AuthorBadges authors={authors} />}
              </div>
            )}
          />
        </div>

        <ScrollProgress>
          <BlogBody>
            <div className="blogMax" ref={ref}>
              {renderBlogBody}
            </div>
          </BlogBody>
        </ScrollProgress>

        <div className="bdContactUs">
          <CtaGetDemo {...cta_get_demo} />
        </div>

        <div className="bpReadMore">
          <VerticalSection
            title="Discover more"
            custom={() => <BlogPosts slug={slug} readMoreIsLink limit={3} />}
          />
        </div>
      </StyledBlogPost>
    </Layout>
  )
}

const StyledBlogPost = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-bottom: 0;
  --section-mobile-padding-bottom: 0;
  --section-max-width: 1080px;

  .heroText {
    --tg-max-width: 715px;
    @media ${device.laptop} {
      margin-left: var(--sp-64);
    }

    .hat {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--spruce);
      margin-bottom: var(--sp-8);

      @media ${device.laptop} {
        margin-bottom: var(--sp-16);
      }

      .date,
      .time {
        font-weight: 500;
      }

      span {
        padding: 0 var(--sp-8);

        font-size: 20px;
        transform: translateY(-2px);
      }
    }
  }

  .bpHero {
    .imageWrap {
      margin-top: var(--sp-32);

      width: 100%;
      max-width: 100%;
      /* max-height: 550px; */

      .gatsbyImageWrap {
        width: 100%;
      }

      .fileImage,
      img {
        border-radius: var(--card-radius);
        width: 100%;
      }
    }
  }

  .bpReadMore {
    --section-padding-top: var(--sp-80);
    --section-mobile-padding-top: var(--sp-48);
  }
`

const BlogBody = styled.section`
  background: var(--bg-background-color);

  .blogMax {
    padding: var(--sp-32) var(--section-mobile-padding);
    /* padding-top: var(--sp-64); */
    max-width: 754px; // should be 720px + 32px padding
    margin: 0 auto;

    @media ${device.laptop} {
      padding: var(--sp-80) var(--section-mobile-padding);
      /* padding-top: 168px; */
    }

    ${richTextBody};
  }
`

const AuthorBadges = ({ authors }) => {
  const renderBadges = () => {
    if (authors.length > 1) {
      return (
        <div className="authorsWrap">
          {map(authors, (author) => (
            <AuthorBadge key={author.id} {...author} small />
          ))}
        </div>
      )
    }

    return <AuthorBadge {...authors[0]} />
  }

  return <StyledAuthorBadges>{renderBadges()}</StyledAuthorBadges>
}

const StyledAuthorBadges = styled.div`
  margin-top: var(--sp-24);
  .authorsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 8px;

    display: grid;
    grid-template-columns: 1fr;
  }
`

const AuthorBadge = ({ photo, fullName, role, slug, isAuthorOnly, small }) => {
  const to = isAuthorOnly ? `/author/${slug}` : `/team/${slug}`

  return (
    <Link to={to}>
      <StyledAuthorBadge className={classNames({ small })}>
        <Media className="authorPhoto" media={{ desktop: photo }} />
        <div className="details">
          <p className="small fullName">{fullName}</p>
          <p className="small role">{role}</p>
        </div>
      </StyledAuthorBadge>
    </Link>
  )
}

const StyledAuthorBadge = styled.div`
  display: inline-grid;
  grid-template-columns: 50px auto;
  align-items: center;
  grid-gap: var(--sp-16);

  &:hover {
    .authorPhoto {
      transform: scale(1.1);
    }
  }

  .authorPhoto {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: relative;
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px var(--spruce);
    transition: 0.2s transform ease-in-out;
  }

  .fullName {
    color: var(--spruce);
  }

  .fullName,
  .role {
    font-weight: 500;
  }

  &.small {
    grid-template-columns: 25px auto;
    .authorPhoto {
      height: 25px;
      width: 25px;
    }

    .details {
      display: flex;
      flex-wrap: wrap;

      width: 100%;

      flex-direction: column;
      @media ${device.tablet} {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
      }

      .role {
        @media ${device.tablet} {
          margin-left: 4px;
          padding-left: 4px;
          border-left: 1px solid var(--dirt);
        }
      }
    }
  }
`

export default BlogPost

export const query = graphql`
  query BlogPostSlug($slug: String!) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      ...BlogPost
    }
    cta_get_demo: contentfulSection(sectionId: { eq: "cta_get_demo" }) {
      ...Section
    }
  }
`
